import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Switch, Colors, Icon } from '@class101/ui';
import { PlayGroundButtonContainer } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "switch"
    }}>{`Switch`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Switch} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Switch, Colors } from '@class101/ui';
`}</code></pre>
    <Playground __position={1} __code={'<PlayGroundButtonContainer>\n  <Switch />\n  <Switch color={Colors.blue500} hoverColor={Colors.blue700} checked />\n</PlayGroundButtonContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Switch,
      Colors,
      Icon,
      PlayGroundButtonContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundButtonContainer mdxType="PlayGroundButtonContainer">
    <Switch mdxType="Switch" />
    <Switch color={Colors.blue500} hoverColor={Colors.blue700} checked mdxType="Switch" />
  </PlayGroundButtonContainer>
    </Playground>
    <h2 {...{
      "id": "with-text"
    }}>{`With Text`}</h2>
    <p><inlineCode parentName="p">{`labeComponent`}</inlineCode>{`와 `}<inlineCode parentName="p">{`children`}</inlineCode>{`의 타입은 같습니다.`}</p>
    <p><strong parentName="p">{`Checked 상태에 따라 텍스트가 변하는 것은 UX적으로 추천하지 않습니다.`}</strong></p>
    <Playground __position={2} __code={'<PlayGroundButtonContainer style={{ width: \'250px\' }}>\n  <Switch checked>크레딧 사용</Switch>\n  <Switch labelComponent=\"내 위치 공유\" />\n  <Switch disabled>\n    <span>벨소리 사용</span>\n  </Switch>\n  <Switch labelComponent={checked => (checked ? \'checked\' : \'unchecked\')} />\n</PlayGroundButtonContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Switch,
      Colors,
      Icon,
      PlayGroundButtonContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundButtonContainer style={{
        width: '250px'
      }} mdxType="PlayGroundButtonContainer">
    <Switch checked mdxType="Switch">크레딧 사용</Switch>
    <Switch labelComponent="내 위치 공유" mdxType="Switch" />
    <Switch disabled mdxType="Switch"><span>벨소리 사용</span></Switch>
    <Switch labelComponent={checked => checked ? "checked" : "unchecked"} mdxType="Switch" />
  </PlayGroundButtonContainer>
    </Playground>
    <h2 {...{
      "id": "checked--disabled"
    }}>{`Checked & Disabled`}</h2>
    <Playground __position={3} __code={'<PlayGroundButtonContainer>\n  <Switch checked />\n  <Switch disabled />\n</PlayGroundButtonContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Switch,
      Colors,
      Icon,
      PlayGroundButtonContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundButtonContainer mdxType="PlayGroundButtonContainer">
    <Switch checked mdxType="Switch" />
    <Switch disabled mdxType="Switch" />
  </PlayGroundButtonContainer>
    </Playground>
    <h2 {...{
      "id": "block-change"
    }}>{`Block Change`}</h2>
    <p><inlineCode parentName="p">{`return false`}</inlineCode>{`를 하면 값이 변하지 않습니다.`}</p>
    <Playground __position={4} __code={'<PlayGroundButtonContainer>\n  <Switch\n    onChange={e => {\n      if (e.target.checked === false) return false\n    }}\n  />\n</PlayGroundButtonContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Switch,
      Colors,
      Icon,
      PlayGroundButtonContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundButtonContainer mdxType="PlayGroundButtonContainer">
    <Switch onChange={e => {
          if (e.target.checked === false) return false;
        }} mdxType="Switch" />
  </PlayGroundButtonContainer>
    </Playground>
    <h2 {...{
      "id": "inline"
    }}>{`Inline`}</h2>
    <Playground __position={5} __code={'<PlayGroundButtonContainer>\n  <Switch inline />\n  <Switch inline />\n</PlayGroundButtonContainer>\n<PlayGroundButtonContainer>\n  <Switch labelComponent=\"다크모드 사용\" inline checked />\n  <Switch labelComponent=\"다크모드 사용\" inline />\n  <Switch labelComponent=\"다크모드 사용\" inline disabled />\n</PlayGroundButtonContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Switch,
      Colors,
      Icon,
      PlayGroundButtonContainer,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundButtonContainer mdxType="PlayGroundButtonContainer">
    <Switch inline mdxType="Switch" />
    <Switch inline mdxType="Switch" />
  </PlayGroundButtonContainer>
  <PlayGroundButtonContainer mdxType="PlayGroundButtonContainer">
    <Switch labelComponent="다크모드 사용" inline checked mdxType="Switch" />
    <Switch labelComponent="다크모드 사용" inline mdxType="Switch" />
    <Switch labelComponent="다크모드 사용" inline disabled mdxType="Switch" />
  </PlayGroundButtonContainer>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      